.seo_generator_wrapper {
  display: flex;
  justify-content: center;
  background-color: var(--primary-color);
  padding: 60px 40px;
}

.seo_generator_content {
  max-width: 800px;
  width: 100%;
  /* padding: 40px; */
  /* background-color: var(--background-color); */
}

.seo_generator_content > h1 {
  color: var(--background-color);
  font-size: 3rem;
  margin-bottom: 20px;
  margin: 0px;
}

.seo_generator_content > p {
  color: var(--background-color);
  font-size: 1rem;
  margin-bottom: 40px;
}


/* -------------------------------------- */
/* dropdown styling */
/* -------------------------------------- */

.seo_generator_dropdown_wrapper {
  width: 200px;
}

.seo_generator_dropdown_container {
  border: 1px solid var(--primary-color);
  padding: 13px !important;
  font-size: 20px;
  font-weight: 600;
}

.seo_generator_dropdown_container>p {
  font-weight: 400;
}

.seo_generator_dropdown_container>p>span {
  font-weight: 600;
  color: var(--primary-color) !important;
}


/* seo chat wrapper */

.seo_chat_wrapper {
  background-color: var(--background-color);
  padding: 40px;
}



/* form wrapper */

.seo_chat_form_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.seo_chat_form_wrapper > h3 {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin: 0px;
}

.seo_chat_input_div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.seo_chat_input_div > label {
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 700;
  margin: 0px;
}

.seo_chat_input_div > input {
  width: 100%;
  max-width: 250px;
  padding: 10px;
  border: 1px solid var(--primary-color);
}

.seo_chat_input_div > textarea {
  width: calc(100% - 20px);
  height: 175px;
  padding: 10px;
  border: 1px solid var(--primary-color);
  resize: none;
}

.seo_chat_input_button_wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.seo_chat_error {
  color: var(--secondary-color);
  font-weight: 600;
  margin: 0px;
}

.seo_chat_input_button_wrapper > button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--background-color);
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.seo_chat_input_button_wrapper > button:hover {
  background-color: var(--background-color);
  color: var(--primary-color);
}

.seo_chat_loader_container {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: var(--background-gray);
  padding: 20px;
  width: 100%;
  margin-top: 20px;
}




