.about_wrapper {
  padding: 40px;
}

/* -------------------------------------- */
/* welcome section */
/* -------------------------------------- */

.about_welcome_section {
  display: flex;
  justify-content: center;
}

.about_welcome_section_left {
  flex: 1;
}

.about_welcome_section_left>h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  max-width: 550px;
  color: var(--primary-color);
}

.about_welcome_section_left>h1>span {
  color: var(--secondary-color);
}

.about_welcome_section_left>p {
  font-size: 1.3rem;
  line-height: 150%;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.about_welcome_section_right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-height: 400px;
  max-height: 600px;
}

.about_welcome_section_right > div {
  /* height: 100%; */
}

.about_team_section>h2 {
  font-size: 3rem;
  font-weight: 400;
  margin: 0;
  margin-bottom: 7px;
  margin-top: 20px;
  font-style: italic;
}

.about_team_section > h2 > span {
  color: var(--secondary-color);
}

@media screen and ( max-width: 768px) {
  .about_wrapper {
    padding-top: 0px;
  }

  .about_welcome_section {
    flex-direction: column-reverse;
    align-items: left;
    margin-bottom: 40px;
  }

  .about_welcome_section_left {
    text-align: left;
  }

  .about_welcome_section_left > h1 {
    margin-top: 0px;
    width: 100%;
    max-width: 100%;
  }

  .about_welcome_section_right {
    width: 100%;
    max-width: 400px;
    max-height: unset;
  }
}