.badge,
.badge svg,
.badge img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  flex: 1;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.badge:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .badge,
  .badge svg,
  .badge img {
    width: 150px;
    height: 150px;
  }
  
}