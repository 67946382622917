.specialty_banner_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.specialty_banner_left_colomn {
  width: 318px;
}

.specialty_banner_left_colomn>h4 {
  font-size: 3rem;
  font-weight: 600;
  color: var(--primary-color);
  margin: 0px;
  /* line-height: 70px; */
}

.specialty_banner_left_colomn>h4 span {
  color: var(--secondary-color);
}

.specialty_banner_right_colomn {
  width: calc(100% - 318px);
  margin-left: 40px;
}

.specialty_banner_right_colomn>p {
  /* display: flex;
  align-items: center; */
  margin: 0px;
  font-size: 1.3rem;
  /* line-height: 70px; */
  color: var(--primary-color);
  display: inline-block;

  margin-top: 23px;
  margin-bottom: 20px;
}

.specialty_banner_right_colomn>p>span {
  margin-left: 3px;
}

.specialty_banner_right_colomn>p>span>a {
  text-decoration: none;
  cursor: pointer;
  color: var(--secondary-color);
}

.specialty_banner_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
}

.specialty_banner_content_wrapper {
  flex: 4;
  min-width: 200px;
}

.specialty_banner_content_wrapper>a {
  text-decoration: none;
  color: var(--secondary-color);
  font-size: 1.5rem;
  font-weight: 500;
}

.specialty_banner_content_specialties {
  margin-top: 10px;
}

.specialty_banner_content_specialties>p {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0px;
  margin-bottom: 5px;
  color: var(--primary-color);
}

.specialty_banner_content_specialties>p:last-child {
  margin-bottom: 0px;
}

@media screen and (max-width: 1300px) {
  .specialty_banner_wrapper {
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-start;
  }

  .specialty_banner_left_colomn {
    width: 100%;
  }

  .specialty_banner_left_colomn>h4 {
    line-height: unset;
  }

  .specialty_banner_right_colomn {
    width: 100%;
    margin-left: 0px;
  }

  .specialty_banner_right_colomn>p {
    height: auto;
    line-height: normal;
    margin-bottom: 20px;
    margin-top: 10px;
  }

}

@media screen and (max-width: 768px) {
  .specialty_banner_content {
    flex-direction: column;
    gap: 10px;
  }

  .specialty_banner_content_wrapper {
    flex: 1;
  }

  .specialty_banner_content_specialties {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .specialty_banner_right_colomn>p {
    margin-bottom: 40px;
  }

  .specialty_banner_right_colomn>p,
  .specialty_banner_left_colomn>h4,
  .specialty_banner_content_wrapper>a, 
  .specialty_banner_content_wrapper {
    text-align: left;
  }

  .specialty_banner_content_specialties {
    justify-content: flex-start;
  }
}

/* @media screen and (max-width: 480px) {
  .specialty_banner_wrapper {
    text-align: center;
    align-items: center
  }
} */