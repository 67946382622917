.blog_layout_container {
  margin: 0px 40px;
}

.blog_layout_container > h2 {
  font-size: 2.5rem;
  margin: 0px;
  color: var(--primary-color);
  max-width: 1000px;
}

.blog_layout_container > h2 > span {
  color: var(--secondary-color);
}

.blog_layout_container > p {
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--primary-color);
  max-width: 1000px;
}

.blog_list_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  /* width: 0px; */
  transition: all 0.3s ease-in;
  margin-top: 30px;
}

.blog_list_container > a {
  cursor: inherit;
  height: 400px;
  flex: 1;

  text-decoration: none;

  transition: all 0.3s ease-in;
  max-width: calc(50% - 10px);
  min-width: 30%;
  /* transition: all 0.3s ease-in; */
}

.blog_footer {
  margin-top: 20px;
}

.blog_dropdown_wrapper {
  width: 200px;
}

.blog_dropdown_container {
  border-bottom: 1px solid var(--primary-color);
  font-size: 20px;
  font-weight: 600;
}

@media only screen and ( max-width: 850px)  { 
  .blog_list_container > a {
    min-width: calc(50% - 10px);
    height: 350px;
  }
}

@media only screen and ( max-width: 450px)  { 
  .blog_list_container > a {
    min-width: 100%;
    max-width: unset;
  }
}