.blog_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog_image_container {
  position: relative;
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  /* max-width: 1000px; */
}

.blog_content_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 40px;
  max-width: 1000px;
  color: var(--primary-color);
}

.blog_content_wrapper h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 7px;
  margin-top: 20px;
}

.blog_content_wrapper h1 > span {
  color: var(--secondary-color);
}

.blog_info_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  color: var(--primary-color) !important;
}

.blog_info_container p, .blog_info_container a {
  margin: 0px;
  font-size: 1.2rem;
}

.blog_info_container a {
  color: var(--secondary-color);
  text-decoration: none;
}

.blog_info_tag_container {
  display: flex;
  align-items: center;
  /* gap: 10px; */
}

.blog_info_tag_container > p {
  margin-right: 10px;
  margin-left: 3px;
}

.blog_info_bullet {
  height: 4px;
  width: 4px;
  margin: 0px 10px;
  background-color: var(--accent-color);
  border-radius: 50%;
}

.blog_content_wrapper hr {
  margin: 0px;
  border: 0px;
  height: 1px;
  background-color: var(--accent-color);
  opacity: 0.3;
  width: 100%;
}

.blog_content_wrapper h3 {
  font-size: 1rem;
  font-weight: 600;
  margin: 10px 0px;
  color: var(--primary-color) !important;
}

.blog_content_body {
  margin-top: 20px;
}

.blog_content_body p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 0px;
  margin-bottom: 20px;
}

.blog_content_body h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 20px 0px;
}

.blog_content_body a {
  color: var(--secondary-color);
  text-decoration: none;
  font-weight: 600;
}

@media only screen and (max-width: 650px) {
  .blog_content_wrapper {
    margin: 0px 20px;
  }
}