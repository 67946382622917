.home_wrapper {}

.home_wrapper h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--primary-color);
  margin: 0px;
  margin-left: 40px;
}

.home_wrapper h2>span {
  color: var(--secondary-color);
}

/* -------------------------------------- */
/* welcome section */
/* -------------------------------------- */

.home_welcome_section {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 60vh; */
  margin: 0px 40px;
  margin-bottom: 20px;
  gap: 40px;
}

.home_welcome_section_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
}

.home_welcome_section_left h1 {
  font-size: 3.5rem;
  font-weight: 600;
  color: var(--primary-color);
  margin: 0px;
}

.home_welcome_section_left h1 span {
  color: var(--secondary-color);
}

.home_welcome_section_left p {
  font-size: 1rem;
  line-height: 1.7rem;
  font-weight: 400;
  margin: 0px;
  margin-top: 10px;
  color: var(--primary-color);
}

.home_welcome_buttons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.home_welcome_buttons a {
  cursor: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 5px 20px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid;
  transition: all 0.3s ease-in-out;
}

.home_welcome_buttons a:first-child {
  background-color: var(--primary-color);
  color: var(--background-color);
  border-color: var(--primary-color);
}

.home_welcome_buttons a:first-child:hover {
  background-color: var(--background-color);
  color: var(--primary-color);
}

.home_welcome_buttons a:last-child {
  background-color: var(--secondary-color);
  color: var(--background-color);
  border-color: var(--secondary-color);
}

.home_welcome_buttons a:last-child:hover {
  background-color: var(--background-color);
  color: var(--secondary-color);
}

.home_welcome_section_right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-height: 440px;
}

/* .home_welcome_section_right img {
  height: auto;
} */



/* -------------------------------------- */
/* services section */
/* -------------------------------------- */

.home_service_section {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin: 20px 0px;
  /* padding: 3px; */
  background-color: var(--primary-color);
}

.home_service_card {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 25%;
  min-height: 100%;
  /* height: 350px; */
  flex: 1;
  /* cursor: pointer; */
  /* padding: 30px; */
  background-color: var(--primary-color);
  border-right: 2px solid var(--accent-color);
  /* border-right: 2px solid red; */
}

.home_service_card:last-child {
  border-right: none;
}

.home_service_card_content {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 90%;
  padding: 30px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.home_service_card_content svg {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.home_service_card_content svg>path {
  fill: var(--background-color);
}

.home_service_card_content h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--background-color);
  margin: 0px;
}

.home_service_card_content p {
  font-size: 1rem;
  line-height: 1.7rem;
  font-weight: 400;
  color: var(--background-color);
  margin: 0px;
  margin-top: 10px;
}

.home_service_card_content a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 7px 20px;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  border: 1px solid;
  background-color: var(--secondary-color);
  color: var(--background-color);
  border-color: var(--secondary-color);

  cursor: inherit;
}

.home_service_card:hover .home_service_card_content {
  background-color: var(--background-color);
  /* border-right: none; */
  transform: scale(0.95);
}

.home_service_card:hover .home_service_card:has(+ .home_service_card) {
  /* border-right: none; */
}

.home_service_card:hover .home_service_card_content h3,
.home_service_card:hover .home_service_card_content p {
  color: var(--primary-color);
}

.home_service_card:hover .home_service_card_content svg>path {
  fill: var(--primary-color);
}

.home_service_card:hover .home_service_card_content a {
  opacity: 1;
}

.home_service_card_content a:hover {
  background-color: var(--background-color);
  color: var(--secondary-color);
}



/* -------------------------------------- */
/* portfolio section */
/* -------------------------------------- */
.home_portfolio_section {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-wrap: wrap; */
  gap: 30px;
  padding: 40px;
}

.home_portfolio_row_1,
.home_portfolio_row_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
  gap: 30px;
}

.home_portfolio_card {
  cursor: inherit;
  flex: 1;
  height: 500px;
  text-decoration: none;
}

/* -------------------------------------- */
/* testimonial section */
/* -------------------------------------- */

.testimonial_icons_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0px 40px;
  margin-top: 20px;
  gap: 20px;

  height: 220px;
  overflow: hidden;
}

/* .testimonial_icon,
.testimonial_icon svg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  flex: 1;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.testimonial_icon:hover {
  transform: scale(1.05);
} */

.testimonial_cta_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 40px;
  background-color: var(--background-gray);
}

.testimonial_cta_div>h3 {
  font-size: 1.7rem;
  font-weight: 600;
  color: var(--primary-color);
  margin: 0px;
  margin-bottom: 20px;
}

.testimonial_cta_div>a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 20px;
  font-size: 1.7rem;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid;
  transition: all 0.3s ease-in-out;
  background-color: var(--secondary-color);
  color: var(--background-color);
  border-color: var(--secondary-color);
}

.testimonial_cta_div>a:hover {
  background-color: var(--background-color);
  color: var(--secondary-color);
}





/* -------------------------------------- */
/* medial queries */

@media only screen and (max-width: 933px) {
  /* -------------------------------------- */
  /* welcome section */
  /* -------------------------------------- */

  .home_welcome_section {
    gap: 0px;
  }

  .home_welcome_section_left {
    width: 60%;
  }

  .home_welcome_section_right {
    width: 40%;
    min-height: 332px;
  }

  .home_wrapper h1 {
    font-size: 3rem;
  }


  /* -------------------------------------- */
  /* services section */
  /* -------------------------------------- */

  .home_service_section {
    flex-wrap: wrap;
    height: auto;
  }

  .home_service_card {
    width: calc(50% - 3px);
    flex: none;
  }

  .home_service_card:nth-child(2) {
    border-right: none;
  }

  .home_service_card:nth-last-child(2),
  .home_service_card:last-child {
    border-top: 2px solid var(--accent-color);
  }

  /* -------------------------------------- */
  /* portfolio section */
  /* -------------------------------------- */
  .home_portfolio_card {
    height: 450px;
  }

}

@media only screen and (max-width: 768px) {

  /* -------------------------------------- */
  /* portfolio section */
  /* -------------------------------------- */
  .home_portfolio_row_2 {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .home_portfolio_row_2>.home_portfolio_card {
    width: calc(50% - 15px);
    flex: none;
  }

  /* -------------------------------------- */
  /* testimonial section */
  /* -------------------------------------- */

  .testimonial_icons_wrapper {
    height: 170px;
  }

}

@media only screen and (max-width: 679px) {
  .home_wrapper h2 {
    text-align: center;
    margin: 0px 20px;
  }

  /* -------------------------------------- */
  /* welcome section */
  /* -------------------------------------- */

  .home_welcome_section {
    flex-direction: column;
    text-align: center;
  }

  .home_welcome_section_left {
    width: 100%;
    align-items: center;
  }

  .home_welcome_section_right {
    width: 100%;
    min-height: 332px;
  }

  /* -------------------------------------- */
  /* testimonial section */
  /* -------------------------------------- */
  .testimonial_cta_div {
    text-align: center;
  }


}

@media only screen and (max-width: 650px) {
  /* -------------------------------------- */
  /* welcome section */
  /* -------------------------------------- */

  .home_welcome_section {
    margin: 0px 20px;
    margin-bottom: 20px;
  }

  /* -------------------------------------- */
  /* services section */
  /* -------------------------------------- */

  .home_service_card_content {
    padding: 15px;
  }

  /* -------------------------------------- */
  /* portfolio section */
  /* -------------------------------------- */

  .home_portfolio_section {
    padding: 20px;
    gap: 20px;
  }

  .home_portfolio_row_1,
  .home_portfolio_row_2 {
    gap: 20px;
  }

  .home_portfolio_card {
    height: 300px;
    width: calc(50% - 10px);
  }

}


@media only screen and (max-width: 570px) {
  /* -------------------------------------- */
  /* testimonial section */
  /* -------------------------------------- */

  .testimonial_icons_wrapper {
    height: 340px;
    justify-content: center;
  }

}

@media only screen and (max-width: 430px) {
  /* -------------------------------------- */
  /* welcome section */
  /* -------------------------------------- */

  .home_welcome_buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 40px);
  }

  .home_welcome_buttons a {
    width: 100%;
    min-width: 150px;
  }


  /* -------------------------------------- */
  /* services section */
  /* -------------------------------------- */

  .home_service_section {
    flex-direction: column;
    text-align: center;
  }

  .home_service_card {
    width: 100%;
    flex: 1;
    border-right: none;
    border-top: 2px solid var(--accent-color);
  }

  .home_service_card:first-child {
    border-top: none;
  }

  /* -------------------------------------- */
  /* portfolio section */
  /* -------------------------------------- */

  .home_portfolio_row_1,
  .home_portfolio_row_2 {
    flex-direction: column;
  }

  .home_portfolio_card {
    width: 100% !important;
    flex: none;
  }

  /* -------------------------------------- */
  /* testimonial section */
  /* -------------------------------------- */
  .testimonial_icons_wrapper {
    height: 240px;
  }

  .testimonial_icon,
  .testimonial_icon img,
  .testimonial_icon>div,
  .testimonial_icon>div>div>svg {
    width: 100px !important;
    height: 100px !important;
  }

}