.suggested_blogs_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.suggested_blogs_container > h4 {
  font-size: 2.5rem;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 20px;
    color: var(--primary-color);
}

.suggested_blogs_container > h4 > span {
  color: var(--secondary-color);
}

.suggested_blogs_card_container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  gap: 20px;
}

.suggested_blogs_card_container > a {
  cursor: inherit;
  height: 300px;
  flex: 1;
  text-decoration: none;
}

@media only screen and (max-width: 636px) {
  .suggested_blogs_card_container {
    flex-direction: column;
    width: 100%;
  }

  .suggested_blogs_card_container > a {
    width: 100%;
    flex: unset;
  }
}
